<template>
  <div class="home-container">
    <div class="article-list">
      <el-button
        class="create-article"
        type="primary"
        @click="createArticleHandler"
        >New Article</el-button
      >
      <template v-for="(item, index) in articleList">
        <baseCard v-bind="item" @refreshData="queryData" :key="index" />
      </template>
    </div>
    <el-dialog
      title="Create a New Article"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <div class="label">Please select a section for the new article:</div>
      <el-radio-group v-model="type">
        <el-radio :label="1">Campus Pulses</el-radio>
        <el-radio :label="2">Intellect in Words</el-radio>
        <el-radio :label="3">Next Wave Business Review</el-radio>
        <el-radio :label="4">Apex Stage</el-radio>
        <el-radio :label="5">Literature and Art Review</el-radio>
        <el-radio :label="6">History Perspectives</el-radio>
        <el-radio :label="7">Comparative Thinking</el-radio>
        <el-radio :label="8">Professor's Academic Challenge</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCreate">Create</el-button>
      </span>
    </el-dialog>
    <div class="customer-info">
      <customerInfo />
    </div>
  </div>
</template>

<script>
import baseCard from "@/components/baseCard.vue";
import customerInfo from "@/components/customerInfo.vue";
import { getUserArticles } from "../api/index";
import { getUserId } from "@/utils/store";
export default {
  name: "home",

  components: {
    baseCard,
    customerInfo,
  },

  data() {
    return {
      articleList: [],
      dialogVisible: false,
      type: 1,
    };
  },

  mounted() {
    this.queryData();
  },

  methods: {
    queryData() {
      const user_id = getUserId();
      getUserArticles(user_id).then((res) => {
        const { data, code } = res.data;
        if (code === 0) {
          this.articleList = data
            .map(function (item) {
              const {
                member_status,
                article_id,
                article,
                student_id,
                inviter = {},
              } = item;
              const {
                _id,
                title,
                content,
                create_time,
                icon,
                tags,
                cover_address,
                content_status,
                type,
              } = article || {};
              return {
                member_status,
                student_id,
                article_id,
                _id,
                title,
                content,
                create_time,
                icon,
                tags,
                cover_address,
                content_status,
                type,
                member_id: item._id.$id,
                inviter_name: inviter.student_givenName_pinyin
                  ? `${inviter.student_givenName_pinyin} ${inviter.student_lastName_pinyin}`
                  : "",
              };
            })
            .filter((i) => i.content_status === "draft");
        }
      });
    },
    createArticleHandler() {
      this.dialogVisible = true;
    },
    handleCreate() {
      this.dialogVisible = false;
      this.$router.push({
        path: "edit",
        query: {
          id: "",
          action: "draft",
          // type为section类型
          setionType: this.type,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;
  display: flex;

  .article-list {
    flex: 1;
    margin-right: 20px;
    .create-article {
      margin-bottom: 20px;
    }
  }
}
/deep/.el-dialog__header {
  text-align: center;
  .el-dialog__title {
    color: #eb7269;
    font-family: GravityBold;
  }
}
/deep/.el-dialog__footer {
  text-align: center;
}
/deep/.el-radio {
  width: 42%;
  float: left;
  margin-bottom: 20px;
}
.label {
  padding-bottom: 30px;
}
</style>
